import { useEffect, useMemo, useState } from 'react';
import { getValidResponseBody } from '../api';
import { useUserProfile } from './useUserProfile';
import { useAuth0Wrapper } from './auth0';
import searchApi from '../api/searchApi';
import { useDispatch, useSelector } from 'react-redux';
import { setAnonymousRecentlyViewedProducts } from '../state/anonymousRecentlyViewedProducts/anonymousRecentlyViewedProducts';

export const useRecentlyViewedProducts = () => {
  const { tokenClaims } = useAuth0Wrapper();
  const dispatch = useDispatch();
  const profile = useSelector(({ profile }) => profile);
  const { isAuthenticated } = useAuth0Wrapper();
  const anonymousRecentlyViewedProducts = useSelector(
    ({ anonymousRecentlyViewedProducts }) => anonymousRecentlyViewedProducts
  );
  const deleteAnonymousRecentlyViewedProducts = () => {
    dispatch(setAnonymousRecentlyViewedProducts([]));
  };
  const authToken = tokenClaims?.__raw;

  const pickRVP = (profile, anonymousRecentlyViewedProducts) =>
    profile ? profile?.recentlyViewedProducts : anonymousRecentlyViewedProducts;

  const productIds = useMemo(() => {
    const rvp = pickRVP(profile, anonymousRecentlyViewedProducts);
    if (Array.isArray(rvp)) {
      return rvp
        .slice()
        .reverse()
        .map((productId) => productId.productId);
    } else return [];
  }, [anonymousRecentlyViewedProducts, profile]);

  const { removeViewedProducts } = useUserProfile();

  const deleteProducts = () => {
    if (!isAuthenticated) {
      localStorage.removeItem('rVP');
      deleteAnonymousRecentlyViewedProducts();
      return;
    }

    removeViewedProducts();
  };

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);

  useEffect(() => {
    (async () => {
      if (!productIds.length) {
        if (recentlyViewedProducts?.length) setRecentlyViewedProducts([]);
        return;
      }
      //There are productIds, fetch products data
      const rvp = await searchApi.getProductProfile({
        headers: { Authorization: !!authToken ? `Bearer ${authToken}` : undefined },
        method: 'POST',
        body: JSON.stringify({ ids: productIds }),
      });

      setRecentlyViewedProducts(getValidResponseBody(rvp) || []);
    })();
  }, [productIds, authToken, recentlyViewedProducts?.length]);

  return { recentlyViewedProducts, deleteProducts };
};
